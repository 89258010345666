import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router';
import RoutesHelper from '../../../helpers/RoutesHelper';
import { TableStateProvider } from '../../../providers/dashboard/TableStateProvider';
import { SessionViews } from '../WfaDashboardConsts';
import { WfaSingleSessionTabs } from './WfaSingleSessionTabs';
import { WfaSingleSessionHeader } from './header/WfaSingleSessionHeader';
import { WfaSingleSessionTabContent } from './views/WfaSingleSessionTabContent';
import './WfaSingleSessionPage.scss';

interface IWfaSingleSessionPageProps {
	viewId?: SessionViews;
}

export const WfaSingleSessionPage: FC<IWfaSingleSessionPageProps> = ({
	viewId = SessionViews.OVERVIEW
}) => {
	const history = useHistory();
	const { sessionId } = useParams<{ [key: string]: string }>();

	const onSessionNotFoundCallback = (): void => {
		history.push(RoutesHelper.getNotFound404Page(RoutesHelper.getWfaDashboardPath()));
	};

	return (
		<div className="wfa-session-page">
			<WfaSingleSessionHeader
				sessionId={sessionId}
				onSessionNotFoundCallback={onSessionNotFoundCallback}
			/>
			<WfaSingleSessionTabs viewId={viewId} sessionId={sessionId} />
			<TableStateProvider>
				<WfaSingleSessionTabContent sessionId={sessionId} viewId={viewId} />
			</TableStateProvider>
		</div>
	);
};

import React, { FC, useCallback, useEffect, useState } from 'react';
import {
	EvTitle,
	HeadingLevelRange,
	ScreenshotDetails,
	TITLE_MODES
} from '@evinced-private/ui-common';
import { screenshotService } from 'src/services/ScreenshotService';
import logger from 'src/services/Logger';
import { SCREENSHOT_BLOCK_DAID } from 'src/consts/data-automation-ids';
import './ScreenshotBlock.scss';

interface IScreenshotBlockProps {
	screenshotDetails: ScreenshotDetails;
	subheadingsLevel: HeadingLevelRange;
}

export const ScreenshotBlock: FC<IScreenshotBlockProps> = ({
	screenshotDetails,
	subheadingsLevel
}) => {
	const [image, setImage] = useState<string>(null);
	const resolveImage = useCallback(
		async (screenshotDescriptor: ScreenshotDetails): Promise<void> => {
			try {
				const screenshotImageData = await screenshotService.getFullPageScreenshotWithIssues(
					screenshotDescriptor
				);
				setImage(screenshotImageData);
			} catch (e) {
				logger.warn('Screenshot could not be loaded', e);
				setImage(null);
			}
		},
		[]
	);

	useEffect(() => {
		if (!image && screenshotDetails) {
			resolveImage(screenshotDetails);
		}
	}, [screenshotDetails, image, resolveImage]);

	return (
		image && (
			<div className="screenshot-block" data-automation-id={SCREENSHOT_BLOCK_DAID}>
				<EvTitle
					titleText="Screenshot"
					mode={TITLE_MODES.NORMAL}
					className="subheading"
					headingLevel={subheadingsLevel}
				/>
				<div className="screenshot-picture">
					<img className="screenshot-img" src={image} alt="Issue Screenshot" />
				</div>
			</div>
		)
	);
};

import { IssueDetailsHelper, ScreenshotDetails } from '@evinced-private/ui-common';

import { IssueDetailsQuery } from '../IssueDetails.generated';
import { buildExtendedLabels } from '../../../../../helpers/GQLRequestsHelper/QueryResultsTransformHelper';
import { ProductType } from '../../../../../interfaces/Tenant';
import {
	PlatformIssueDetails,
	IIssueElement,
	IIssueTag,
	PlatformLabel
} from '../../../../../types/IssueDetailsType';

export const transformIssueDetailsResults = (
	rawResults: IssueDetailsQuery,
	product: ProductType
): PlatformIssueDetails | null => {
	if (!rawResults.issues?.length) {
		return null;
	}

	const issue = rawResults.issues[0];
	if (!issue) {
		return null;
	}

	const elements: IIssueElement[] = [];
	const tags: IIssueTag[] = [];

	// Quote from BE:
	// you can rely on the order. I,e. number 0 corresponds with 0 in the other array
	// where the prefix is the same: e.g. tags_,elements_.
	// In a perfect world it would be an array of object,
	// but this is how we modeled it for other reasons.
	issue.elements__id.forEach((id, index) => {
		const {
			elements__component_id: componentIds,
			elements__dom_snippet: domSnippets,
			elements__selector: selectors,
			elements__page_url: pageUrls,
			elements__page_title: pageTitles,
			elements__bounding_box_left: boundingBoxLefts,
			elements__bounding_box_top: boundingBoxTops,
			elements__bounding_box_width: boundingBoxWidths,
			elements__bounding_box_height: boundingBoxHeights
		} = issue;

		elements.push({
			id,
			componentId: componentIds[index],
			domSnippet: domSnippets[index],
			selector: selectors[index],
			pageUrl: pageUrls[index],
			pageTitle: pageTitles[index],
			boundingBox: {
				x: boundingBoxLefts[index],
				y: boundingBoxTops[index],
				width: boundingBoxWidths[index],
				height: boundingBoxHeights[index]
			}
		});
	});

	issue.tags__id.forEach((id, index) => {
		tags.push({
			id,
			description: issue.tags__description?.[index],
			link: issue.tags__link?.[index]
		});
	});

	const labels: PlatformLabel[] = buildExtendedLabels({
		product,
		flow: issue.flow,
		environment: issue.environment,
		runTime: issue.run_time,
		properties: issue.property_ids,
		customFields: issue.custom_fields,
		errorMessage: `Missing data in IssueDetails query for issue ${issue.issue_id}: `
	});

	const screenshotDetails: ScreenshotDetails = {
		pageScreenshotUrl: issue.screenshot,
		boundingBoxes: elements?.map((element) => element.boundingBox) || []
	};

	const result: PlatformIssueDetails = {
		id: issue.issue_id,
		type: issue.type_name,
		summary: issue.summary,
		url: issue.url,
		elements,
		tags,
		severity: issue.severity_name,
		knowledgeBaseLink: issue.knowledge_base_link,
		screenshotUrl: issue.screenshot,
		cause: IssueDetailsHelper.getCauseString(issue.description),
		effect: IssueDetailsHelper.getEffectString(issue.description),
		howToFix: IssueDetailsHelper.getHowToString(issue.description),
		level: issue.conformance[0],
		description: issue.description,
		propertyIds: issue.property_ids,
		note: issue.note,
		labels,
		screenshotDetails
	};
	return result;
};

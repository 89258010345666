import { OptionType } from '@evinced-private/ui-common';
import SeverityService from '../services/severities/SeverityService';
import { DEFAULT_PERIOD_OPTION } from '../pages/web-sdk-dashboard/web-sdk-consts';
import {
	MFA_SESSIONS_TABLE_ID,
	MSDK_SESSIONS_TABLE_ID,
	WEB_SDK_TEST_ISSUES_TABLE_ID,
	WEB_SDK_TEST_TABLE_ID,
	WFA_SESSIONS_TABLE_ID,
	WFA_SESSION_ISSUES_TABLE_ID,
	WFA_SESSION_COMPONENTS_TABLE_ID
} from '../pages/dashboards-container/dashboard_consts';

enum FieldOptionName {
	SEVERITY = 'severity',
	TYPE = 'type',
	TAGS = 'tags'
}

interface IMobileSessionsFilterOption {
	searchValue?: string;
	os?: OptionType[];
	apps?: OptionType[];
	versions?: OptionType[];
}

export interface IWfaSessionsFilterOption {
	searchValue?: string;
	sessionsPeriod?: OptionType[];
	createdBy?: OptionType[];
}

interface IMobileSdkTestsFilterOption {
	searchValue?: string;
	userLabels?: OptionType[];
}

export interface IWebSdkTestsFilterOption {
	searchValue?: string;
	testPeriod?: OptionType[];
	labels?: OptionType[];
	properties?: OptionType[];
}

export interface IWebSdkTestIssuesFilterOption {
	searchValue?: string;
	severities?: OptionType[];
	types?: OptionType[];
	levels?: OptionType[];
	urls?: OptionType[];
	properties?: OptionType[];
}

export interface IFilterOptions
	extends IWebSdkTestsFilterOption,
		IMobileSessionsFilterOption,
		IMobileSdkTestsFilterOption,
		IWfaSessionsFilterOption,
		IWebSdkTestIssuesFilterOption {}

export const orderByMethod = (values: string[] = [], option = ''): string[] => {
	if (option === FieldOptionName.SEVERITY) {
		return SeverityService.sortSeverities(values);
	}
	return values.sort();
};

export const defaultEmptyFilterOptions: IFilterOptions = {
	searchValue: '',
	os: [],
	apps: [],
	versions: [],
	userLabels: []
};

const defaultWebSdkEmptyFilterOptions: IWebSdkTestsFilterOption = {
	searchValue: '',
	testPeriod: [DEFAULT_PERIOD_OPTION],
	labels: []
};

const defaultWfaEmptyFilterOptions: IWfaSessionsFilterOption = {
	searchValue: '',
	sessionsPeriod: [DEFAULT_PERIOD_OPTION],
	createdBy: []
};

const defaultWebSdkIssuesEmptyFilterOptions: IWebSdkTestIssuesFilterOption = {
	searchValue: '',
	severities: [],
	types: [],
	urls: []
};

const defaultMobileSdkEmptyFilterOptions: IMobileSdkTestsFilterOption = {
	searchValue: '',
	userLabels: []
};

const defaultMFAEmptyFilterOptions: IMobileSessionsFilterOption = {
	searchValue: '',
	os: [],
	apps: [],
	versions: []
};

const defaultWfaSingleSessionFilterOptions: IFilterOptions = {
	searchValue: '',
	severities: [],
	types: [],
	urls: [],
	levels: []
};

export const emptyFilterOptions = (tableIdPrefix: string): IFilterOptions => {
	switch (tableIdPrefix) {
		case WEB_SDK_TEST_TABLE_ID:
			return defaultWebSdkEmptyFilterOptions;
		case WEB_SDK_TEST_ISSUES_TABLE_ID:
			return defaultWebSdkIssuesEmptyFilterOptions;
		case MSDK_SESSIONS_TABLE_ID:
			return defaultMobileSdkEmptyFilterOptions;
		case MFA_SESSIONS_TABLE_ID:
			return defaultMFAEmptyFilterOptions;
		case WFA_SESSIONS_TABLE_ID:
			return defaultWfaEmptyFilterOptions;
		case WFA_SESSION_ISSUES_TABLE_ID:
		case WFA_SESSION_COMPONENTS_TABLE_ID:
			return defaultWfaSingleSessionFilterOptions;
		default:
			return defaultEmptyFilterOptions;
	}
};

export default {
	orderByMethod,
	emptyFilterOptions
};

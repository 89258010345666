import React, { FC } from 'react';
import {
	EVINCED_SEVERITIES,
	EvincedColors,
	EvRaceBar,
	EvSection
} from '@evinced-private/ui-common';
import { SessionComponentType } from '../../../../../../../types/wfa/SessionComponentType';
import RaceBarChartHelper from './RaceBarChartHelper';
import EvChartTableLink from '../ev-chart-table-link/EvChartTableLink';
import NotGroupedComponent from '../../../../../../../consts/NotGroupedComponent';
import './ComponentsRaceBarChart.scss';

interface IComponentsRaceBarChartProps {
	sessionComponents: SessionComponentType[];
	onClick: (componentId: string) => void;
}
const ComponentsRaceBarChart: FC<IComponentsRaceBarChartProps> = ({
	sessionComponents,
	onClick
}: IComponentsRaceBarChartProps) => {
	const highestSeverity = 'CRITICAL'; // todo: get the highest severity from the server

	const { barData, totalPercentageCovered } =
		RaceBarChartHelper.prepareDataForChart(sessionComponents);

	const componentIdFieldFormatter = (componentId: string): React.ReactNode => {
		return (
			<EvChartTableLink
				value={componentId}
				ariaLabel={
					componentId === NotGroupedComponent.DISPLAY_NAME
						? NotGroupedComponent.DISPLAY_NAME
						: `Component ID ${componentId}`
				}
				onClick={() => {
					onClick(componentId);
				}}
			/>
		);
	};

	return (
		<EvSection className="components-race-bar-chart">
			<EvRaceBar
				className="components-race-bar-chart-inner"
				title="Breakdown by Component"
				description={`${barData.length} components cover ${totalPercentageCovered.toFixed(2)}% of ${
					EVINCED_SEVERITIES[highestSeverity]
				} issues`}
				data={barData}
				itemTypeLabel="Component"
				maxHeight={345}
				itemTooltipPercentDescription={`of ${highestSeverity} issues`}
				color={EvincedColors.newSeverityColors.CRITICAL}
				headingLevel={2}
				onBarClick={(barItem) => {
					const { id } = barItem;
					onClick(id);
				}}
				tableProps={{
					columnsPattern: [
						{ name: 'Component ID', field: 'id', formatter: componentIdFieldFormatter },
						{ name: 'Issues Percentage', field: 'percent' }
					]
				}}
			/>
		</EvSection>
	);
};

export default ComponentsRaceBarChart;

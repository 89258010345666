import { BUTTON_TYPES, EvButton } from '@evinced-private/ui-common';
import React, { FC, useCallback, useEffect } from 'react';
import RoutesHelper from 'src/helpers/RoutesHelper';
import { getBaseURL } from 'src/helpers/UrlNormalizingHelper';
import { ProductType } from '../../interfaces/Tenant';
import { useIssueDetailsQuery } from '../../services/common/hooks/useIssueDetailsQuery';
import DetailsBlock from '../common/details-block/DetailsBlock';
import CopyToClipboard from '../common/copy-to-clipboard/CopyToClipboard';
import TicketsSystems from '../tickets-systems/TicketsSystems';
import { mapPlatformIssueDetailsToReport } from '../common/details-block/DetailsBlockHelper';

interface IWfaDetailsBlockProps {
	runId: string;
	issueId: string;
	setError?: (error: unknown) => void;
	isFullPageMode?: boolean;
}

export const WfaDetailsBlock: FC<IWfaDetailsBlockProps> = ({
	runId,
	issueId,
	setError,
	isFullPageMode
}) => {
	const issueDetailsQuery = useIssueDetailsQuery({
		issueId,
		runId,
		product: ProductType.WEB_FLOW_ANALYZER
	});

	useEffect(() => {
		if (issueDetailsQuery.isError) {
			setError?.(issueDetailsQuery.error);
		}
	}, [issueDetailsQuery.isError, issueDetailsQuery.error, setError]);

	const issuePageUrl = RoutesHelper.getWfaIssueInfoPath({
		runId,
		issueId
	});

	const urlToCopy = `${getBaseURL()}${issuePageUrl}`;

	const issue = issueDetailsQuery.data;
	const {
		type,
		severity,
		elements,
		level,
		labels,
		summary,
		cause,
		howToFix,
		effect,
		knowledgeBaseLink,
		tags,
		note,
		screenshotDetails
	} = issue || {};
	const { pageUrl, pageTitle } = elements?.[0] ?? {};

	const openIssueInNewTab = useCallback((): void => {
		window.open(issuePageUrl, '_blank');
	}, [issuePageUrl]);

	const topRowActions = (
		<div className="action-buttons">
			{/* In common - pass topBarActionsSection */}
			<CopyToClipboard textToCopy={urlToCopy} buttonType={BUTTON_TYPES.SECONDARY} />
			{!isFullPageMode && (
				<EvButton
					type={BUTTON_TYPES.SECONDARY}
					title="Open in a New Tab"
					onClick={openIssueInNewTab}
				>
					Open in a New Tab
				</EvButton>
			)}
			{!issueDetailsQuery.isLoading && (
				<TicketsSystems issue={mapPlatformIssueDetailsToReport(issue)} />
			)}
		</div>
	);
	return (
		<DetailsBlock
			issue={{
				type,
				severity,
				elements,
				level,
				labels,
				summary,
				cause,
				howToFix,
				effect,
				knowledgeBaseLink,
				tags,
				pageUrl,
				pageTitle,
				note,
				screenshotDetails
			}}
			isLoading={issueDetailsQuery.isLoading}
			isFullPageMode={isFullPageMode}
			topRowActions={topRowActions}
			showNote
		/>
	);
};

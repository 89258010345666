import React, { FC, useCallback, useEffect, useState } from 'react';
import { useUserTenant } from 'src/providers/userTenantProvider/UserTenantProvider';
import { ProductType } from 'src/interfaces/Tenant';
import { ITableStateContext, useTableState } from 'src/providers/dashboard/TableStateProvider';
import { MSdkTest } from 'src/pages/mobile-sdk-dashboard/mobile-sdk-types';
import {
	buildMSDKSummaryPaneData,
	buildMSdkTestList
} from 'src/pages/mobile-sdk-dashboard/msdk-dashboard-helper';
import {
	EvFilters,
	EvSection,
	EvSpinner,
	EvTitle,
	IFilterValues,
	SORT_ORDER,
	TABLE_SR_SUMMARY_IDS,
	useSkipLinks
} from '@evinced-private/ui-common';
import LoadErrorMsgLayout from 'src/pages/property/LoadErrorMsgLayout';
import {
	assertUserLabelsToData,
	buildTableFilters,
	getFilteredSDKTest,
	getMsdkTestsColumns
} from 'src/pages/mobile-sdk-dashboard/msdk-tests-table-helper';
import PlatformTable from 'src/components/common/platform-table/PlatformTable';
import { ContactUsButton } from 'src/components/contact-us/ContactUsButton';
import { omit } from 'lodash-es';
import Logger from '../../services/Logger';
import ErrorLoadPageIcon from '../../components/icons/ErrorLoadPageIcon.svg';
import { NO_SYNCED_TESTS_MSG, NO_SYNCED_TESTS_TITLE } from './msdk-dashboard-consts';
import { SummaryItem } from '../../interfaces/SummaryItem';
import SummaryPane from '../../components/common/summary-pane/SummaryPane';
import MobileSdkDashboardService from '../../services/mobile-sdk-dashboard/MobileSdkDashboardService';
import {
	LAST_MONTH_USAGE_TOOLTIP,
	MSDK_SESSIONS_TABLE_ID
} from '../dashboards-container/dashboard_consts';
// TODO: create a general css file
import { mobileSdkTestsFilterDefinitions } from './mobile-sdk-consts';
import { flattenObject } from '../../helpers/ObjectsHelper';
import { IFilterOptions } from '../../helpers/DataFilterHelper';
import './MSdkDashboardSessionsPage.scss';

const MSdkDashboardTestsPage: FC = () => {
	const { tenant, getProductIdByType } = useUserTenant();
	const productId = getProductIdByType(ProductType.MOBILE_SDK);

	const tableStateContext: ITableStateContext = useTableState();
	const tableState = tableStateContext.getTableStateById(MSDK_SESSIONS_TABLE_ID);

	const [isLoading, setIsLoading] = useState(true);
	const [allMSdkTests, setAllMSdkTests] = useState<MSdkTest[]>(null);
	const [summaryPaneData, setSummaryPaneData] = useState<SummaryItem[]>([]);
	const [tableFilters, setTableFilters] = useState<IFilterOptions>();
	const [tableData, setTableData] = useState<MSdkTest[]>([]);

	const initialValues = {
		text: { searchValue: tableState?.filters.searchValue },
		select: omit(tableState?.filters, 'searchValue')
	};

	useSkipLinks(!isLoading);

	const updateFilters = useCallback(
		(appliedFilters: IFilterValues): void => {
			const flatFilters = flattenObject<IFilterOptions>(appliedFilters);
			const filteredSessions = getFilteredSDKTest(allMSdkTests, flatFilters);

			setTableData(filteredSessions);
			tableStateContext.onFilterChange(MSDK_SESSIONS_TABLE_ID, flatFilters);
		},
		[allMSdkTests, tableStateContext]
	);

	const loadData = useCallback(async (): Promise<void> => {
		try {
			// 1. Fetch MSDK Tests Data
			const res = await MobileSdkDashboardService.getMobileSdkSessions({
				tenantId: tenant.id,
				productId
			});
			const msdkTests: MSdkTest[] = assertUserLabelsToData(buildMSdkTestList(res));
			setAllMSdkTests(assertUserLabelsToData(msdkTests));

			if (msdkTests.length > 0) {
				// Build table filters from data
				const filters: IFilterOptions = buildTableFilters(msdkTests);
				setTableFilters(filters);

				// Build table data based on filters
				const filteredSessions = getFilteredSDKTest(msdkTests, tableState?.filters);
				setTableData(filteredSessions);
			}
		} catch (err) {
			Logger.error('failed to load Mobile SDK dashboard data', err);
		}
	}, [tenant.id, productId, tableState?.filters]);

	useEffect(() => {
		loadData().finally(() => setIsLoading(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const lastMonthData: SummaryItem[] = buildMSDKSummaryPaneData(tableData);
		setSummaryPaneData(lastMonthData);
	}, [tableData]);

	if (isLoading) {
		return <EvSpinner />;
	}
	if (allMSdkTests?.length === 0) {
		return (
			<LoadErrorMsgLayout
				mainTitle={NO_SYNCED_TESTS_TITLE}
				secondaryMsg={NO_SYNCED_TESTS_MSG}
				icon={ErrorLoadPageIcon}
				className="dashboard-no-sessions-msg"
			>
				<ContactUsButton product={ProductType.MOBILE_SDK} />
			</LoadErrorMsgLayout>
		);
	}
	return (
		<div className="msdk-sessions-page">
			<EvTitle titleText="Mobile SDK report" headingLevel={1} />

			<EvSection
				ariaLabel="Mobile SDK All Tests Table"
				ariaDescribedby={TABLE_SR_SUMMARY_IDS}
				ariaLive="polite"
				skipLinkId="main-section"
			>
				<div className="filter-line">
					{tableFilters && (
						<EvFilters
							className="scan-data-filter"
							filterDefinitions={mobileSdkTestsFilterDefinitions}
							initialValues={initialValues}
							filterOptions={{ userLabels: tableFilters.userLabels }}
							isFilterOptionsLoaded={!isLoading}
							onApply={updateFilters}
							onReset={updateFilters}
						/>
					)}
				</div>

				<EvSection ariaLabel="Mobile SDK last month usage" className="last-month-usage-section">
					<EvTitle
						className="last-month-usage-title"
						titleText="Last month usage"
						id="Last month usage title"
						// eslint-disable-next-line react/jsx-no-useless-fragment
						MoreInfoContent={<>{LAST_MONTH_USAGE_TOOLTIP}</>}
					/>

					<SummaryPane
						items={summaryPaneData}
						className="base-summary-pane"
						ariaLabel="Mobile SDKs monthly Usage summary"
					/>
				</EvSection>

				<div className="table-title-and-actions-container">
					<EvTitle
						titleText="All tests"
						className="all-session-table-title"
						id="Mobile SDK All SeTestsssions"
					/>
				</div>

				<PlatformTable
					tableId={MSDK_SESSIONS_TABLE_ID}
					tableData={tableData}
					totalCount={tableData.length}
					className="mfa-sessions-table"
					persistTableState
					columns={getMsdkTestsColumns()}
					options={{
						keyField: 'testRunId',
						title: 'Mobile SDK Tests',
						caption: 'Mobile SDK Tests table',
						pagination: true,
						dataType: 'tests',
						defaultSorted: [
							{
								dataField: 'createdAt',
								order: SORT_ORDER.DESC
							}
						],
						// TODO: remove once everything done remotely
						localProps: {
							defaultPage: 1,
							defaultSizePerPage: 10
						}
					}}
				/>
			</EvSection>
		</div>
	);
};

export default MSdkDashboardTestsPage;

import { escape } from 'lodash-es';
import React, { FC } from 'react';
import { EvMarkdownViewer, HeadingLevelRange } from '@evinced-private/ui-common';
import { TextBlockWithTitle } from '../TextBlockWithTitle';

interface IIssueMarkdownTextBlockProps {
	sectionName: string;
	stringToRender: string;
	headingLevel?: HeadingLevelRange;
}

export const IssueMarkdownTextBlock: FC<IIssueMarkdownTextBlockProps> = ({
	sectionName,
	stringToRender,
	headingLevel
}): JSX.Element => {
	if (!stringToRender) {
		return null;
	}
	return (
		<TextBlockWithTitle
			name={sectionName}
			value={<EvMarkdownViewer initialValue={escape(stringToRender)} />}
			headingLevel={headingLevel}
		/>
	);
};

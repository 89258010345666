export const NO_SYNCED_SESSIONS_TITLE = 'No synced sessions';
export const NO_SYNCED_SESSIONS_MSG =
	'Sync sessions on your desktop app with the Evinced Platform to allow peer collaboration and comparison over time. \nContact us for more details.';

export const ACCESS_DENIED_TITLE = 'Access denied';
export const ACCESS_DENIED_MSG =
	"You don't have permissions to view this page. \nPlease contact us or your account admin to request access.";

export const WEB_SDK_NO_SYNCED_SESSIONS_TITLE = 'No synced tests';
export const WEB_SDK_NO_SYNCED_SESSIONS_MSG =
	'You can sync your tests with the Evinced Platform to allow peer collaboration and comparison over time. \nFor more details, please review our documentation.';

export const WEB_SDK_TEST_INFO_ERROR_MSG = 'There was an error while loading the test metadata.';

export const WEB_SDK_NO_ISSUES_TITLE = 'No issues';
export const WEB_SDK_NO_ISSUES_MSG =
	"This test ran successfully and didn't contain issues, keep up the good work.";

export const DASHBOARD_ERROR_TITLE = 'Failed to load data';
export const DASHBOARD_ERROR_MSG =
	'Please try refreshing the page. \nIf that doesn’t work, please contact Evinced customer support.';

import React, { FC } from 'react';
import { EvTitle, HeadingLevelRange } from '@evinced-private/ui-common';
import { TEXT_BLOCK_WITH_TITLE_DAID } from 'src/consts/data-automation-ids';
import './TextBlockWithTitle.scss';

interface ITextBlockWithTitleProps {
	name: string;
	value: JSX.Element | string;
	headingLevel?: HeadingLevelRange;
}

export const TextBlockWithTitle: FC<ITextBlockWithTitleProps> = ({
	name,
	value,
	headingLevel = 2
}) => {
	if (!value) {
		return null;
	}

	return (
		<div className="text-block-with-title" data-automation-id={TEXT_BLOCK_WITH_TITLE_DAID}>
			<EvTitle headingLevel={headingLevel} className="name" titleText={name} />
			<div className="value">{value}</div>
		</div>
	);
};

import React from 'react';
import { EvTitle, EvTooltip, HeadingLevelRange } from '@evinced-private/ui-common';
import classNames from 'classnames';
import { Tag } from '../tag/Tag';
import { HorizontalDivider } from '../horizontal-divider/HorizontalDivider';
import './TagsBlock.scss';

export interface ITagGroup {
	tags: {
		name: string;
		value: string;
		maxChars?: number;
	}[];
	title?: string;
}

interface ITagsBlockProps {
	tagGroups: ITagGroup[];
	isThreeColumns?: boolean;
	titlesHeadingLevel?: HeadingLevelRange;
}

const tooltipId = 'tags-block-tooltip';

export const TagsBlock: React.FC<ITagsBlockProps> = ({
	tagGroups,
	isThreeColumns,
	titlesHeadingLevel
}) => {
	const renderTagGroup = ({
	tags,
	title
}: ITagGroup, index: number): JSX.Element => {
		const tagsWithValues = tags?.filter((tag) => tag.value);

		const needsHorizontalDivider =
			index !== tagGroups.length - 1 &&
			!!tagsWithValues.length &&
			!!tagGroups[index + 1]?.tags?.filter?.((tag) => tag.value)?.length;

		return (
			!!tagsWithValues.length && (
				<div key={`tag-group-${index}`}>
					{!!title?.length && (
						<EvTitle
							titleText={title}
							headingLevel={titlesHeadingLevel ?? 3}
							className="group-title"
						/>
					)}
					<div
						className={classNames('tags-grid', {
							'three-columns': isThreeColumns
						})}
					>
						{tagsWithValues.map((tag) => (
							<Tag
								key={`${tag.name}-${tag.value}`}
								text={tag.value}
								label={tag.name}
								maxChars={tag.maxChars}
								tooltipId={tooltipId}
							/>
						))}
					</div>
					{needsHorizontalDivider && <HorizontalDivider />}
				</div>
			)
		);
	};

	return (
		<div className="tags-block">
			{tagGroups.map((group, index) => renderTagGroup(group, index))}
			<EvTooltip id={tooltipId} place="bottom" type="info" />
		</div>
	);
};

import React, { FC } from 'react';
import pluralize from 'pluralize';
import { EvLink, EvSnippetView, EvSpinner, EvTitle } from '@evinced-private/ui-common';
import { IComponentSampleIssue } from 'src/types/ComponentSampleIssue';
import {
	COMPONENT_DETAILS_SUMMARY_DAID,
	SNIPPET_VIEW_BLOCK_DAID
} from '../../consts/data-automation-ids';
import { ScreenshotBlock } from '../common/screenshot-block/ScreenshotBlock';
import { TextBlockWithTitle } from '../common/text-block-with-title/TextBlockWithTitle';
import { SelectorTextBlock } from '../common/text-block-with-title/selector-text-block/SelectorTextBlock';
import './ComponentDetailsBlock.scss';

interface IComponentDetailsBlockProps {
	totalIssues: number;
	totalPages: number;
	sampleIssue: IComponentSampleIssue;
	isLoading: boolean;
}

export const ComponentDetailsBlock: FC<IComponentDetailsBlockProps> = ({
	sampleIssue,
	totalIssues,
	totalPages,
	isLoading
}) => {
	const { type, severity, selector, url, domSnippet, screenshotDetails } = sampleIssue ?? {};

	if (isLoading) {
		return (
			<div className="spinner-wrapper">
				<EvSpinner />
			</div>
		);
	}
	if (!type) {
		return null;
	}

	const summaryText = `This component contains ${pluralize(
		'issue',
		totalIssues,
		true
	)} over ${pluralize('page', totalPages, true)}`;

	return (
		<div className="component-details-block">
			<div className="section-header">
				<div className="sub-title" data-automation-id={COMPONENT_DETAILS_SUMMARY_DAID}>
					{summaryText}
				</div>
			</div>
			<div className="element-details-block">
				<EvTitle headingLevel={3} className="subheading" titleText="Example Issue" />
				<div className="component-details">
					<TextBlockWithTitle
						name="URL:"
						value={<EvLink url={url} showIcon={true} openInNewTab={true} />}
						headingLevel={4}
					/>
					<SelectorTextBlock selector={selector} headingLevel={4} />
					<TextBlockWithTitle name="Issue Type:" value={type} headingLevel={4} />
					<TextBlockWithTitle name="Severity:" value={severity} headingLevel={4} />
					<div
						className="snippet"
						aria-label="DOM Snippet"
						data-automation-id={SNIPPET_VIEW_BLOCK_DAID}
					>
						<EvSnippetView title="" snippet={domSnippet} codeType="html" />
					</div>
				</div>
			</div>
			<ScreenshotBlock screenshotDetails={screenshotDetails} subheadingsLevel={3} />
		</div>
	);
};

import { Location } from 'history';
import {
	MSDK_REPORT_ID_PARAM,
	MSDK_TEST_NAME_PARAM
} from 'src/pages/mobile-sdk-dashboard/msdk-dashboard-consts';
import { IGetMSdkSessionsResponse, IMSDKLastMonthSummary, MSdkTest } from './mobile-sdk-types';
import { SummaryItem } from '../../interfaces/SummaryItem';
import MathHelper from '../../helpers/MathHelper';
import RoutesHelper from '../../helpers/RoutesHelper';
import { IEvBreadcrumb } from '../../components/header-navigation/EvBreadcrumbs';
import { MAIN_PAGE_NAV_LABEL } from './mobile-sdk-consts';

// TODO: move to a Dashboard global consts file
const MILIS_PER_DAY = 24 * 60 * 60 * 1000;
const DAYS_TO_LOOK_BACK = 30;

// TODO: refactor to be Generic function for both MFA Sessions and MSDK Tests
const calcMSDKSummaryDataOverTwoMonths = (tests: MSdkTest[]): IMSDKLastMonthSummary => {
	let totalTestsThisMonth = 0;
	let totalScansThisMonth = 0;
	let totalCriticalIssuesThisMonth = 0;

	let totalTestsPrevMonth = 0;
	let totalScansPrevMonth = 0;
	let totalCriticalIssuesPrevMonth = 0;

	const today = new Date();
	const daysAgoInMilis = DAYS_TO_LOOK_BACK * MILIS_PER_DAY;
	const thirtyDaysAgo = new Date(today.getTime() - daysAgoInMilis);
	const sixtyDaysAgo = new Date(thirtyDaysAgo.getTime() - daysAgoInMilis);

	tests.forEach((test: MSdkTest) => {
		const testCreatedDate = new Date(test.createdAt);

		// If session is from the past month
		if (testCreatedDate >= thirtyDaysAgo && testCreatedDate <= today) {
			totalTestsThisMonth++;
			totalScansThisMonth += test.totalScans;
			totalCriticalIssuesThisMonth += test.criticalIssues;
		}

		// If session is from 2 months ago
		if (testCreatedDate >= sixtyDaysAgo && testCreatedDate < thirtyDaysAgo) {
			totalTestsPrevMonth++;
			totalScansPrevMonth += test.totalScans;
			totalCriticalIssuesPrevMonth += test.criticalIssues;
		}
	});

	const totalTestsLift: number = MathHelper.calcPercentageDifference(
		totalTestsThisMonth,
		totalTestsPrevMonth
	);

	const totalScansLift: number = MathHelper.calcPercentageDifference(
		totalScansThisMonth,
		totalScansPrevMonth
	);

	const totalCriticalIssuesLift: number = MathHelper.calcPercentageDifference(
		totalCriticalIssuesThisMonth,
		totalCriticalIssuesPrevMonth
	);

	return {
		totalTests: totalTestsThisMonth,
		totalScans: totalScansThisMonth,
		totalCriticalIssues: totalCriticalIssuesThisMonth,
		totalTestsLift,
		totalScansLift,
		totalCriticalIssuesLift
	};
};

export const buildMSDKSummaryPaneData = (msdkTests: MSdkTest[]): SummaryItem[] => {
	const msdkSummaryData: IMSDKLastMonthSummary = calcMSDKSummaryDataOverTwoMonths(msdkTests);
	return [
		{
			value: msdkSummaryData.totalTests,
			label: 'Total tests',
			lift: msdkSummaryData.totalTestsLift
		},
		{
			value: msdkSummaryData.totalScans,
			label: 'Total scans',
			lift: msdkSummaryData.totalScansLift
		},
		{
			value: msdkSummaryData.totalCriticalIssues,
			label: 'Critical issues',
			lift: msdkSummaryData.totalCriticalIssuesLift
		}
	];
};

export const buildMSdkTestList = (
	msdkTestsFromEndpoint: IGetMSdkSessionsResponse[]
): MSdkTest[] => {
	return msdkTestsFromEndpoint.map((session) => session.data);
};

export const getMsdkReportIdParam = (location): string => {
	const params = location.search;
	return new URLSearchParams(params).get(MSDK_REPORT_ID_PARAM);
};

const getMsdkTestNameParam = (location): string => {
	const params = location.search;
	return new URLSearchParams(params).get(MSDK_TEST_NAME_PARAM);
};

export const getMsdkDashboardBreadcrumbsRoutes = (location: Location<object>): IEvBreadcrumb[] => {
	return [
		{
			id: MAIN_PAGE_NAV_LABEL,
			url: RoutesHelper.getMobileSdkDashboardPath(),
			title: MAIN_PAGE_NAV_LABEL
		},
		{
			id: getMsdkReportIdParam(location),
			url: '',
			title: getMsdkTestNameParam(location)
		}
	];
};

// This file was copied from site scanner. Planned to move it to ui-common
import React, { FC, ReactNode, useMemo } from 'react';
import classNames from 'classnames';
import { EvLink, EvSection, EvSpinner, EvTitle, TITLE_MODES } from '@evinced-private/ui-common';
import {
	IssueMarkdownTextBlock
} from '../text-block-with-title/issue-markdown-text-block/IssueMarkdownTextBlock';
import { CollapsiblePane } from '../collapsible-pane/CollapsiblePane';
import { LabelsBlock } from '../../labels-block/LabelsBlock';
import { KnowledgeBaseLink } from './KnowledgeBaseLink';
import { HowToFixBlock } from './HowToFixBlock';
import { WcagTagsLinksBlock } from './WcagTagsLinksBlock';
import { ElementDetailsBlock } from './ElementDetailsBlock';
import { IssueDetailsBlockSingleIssueData } from './DetailsBlockTypes';
import { ScreenshotBlock } from '../screenshot-block/ScreenshotBlock';
import { TextBlockWithTitle } from '../text-block-with-title/TextBlockWithTitle';
import { NoteBlock } from './NoteBlock';
import './DetailsBlock.scss';

interface IDetailsProps {
	issue: IssueDetailsBlockSingleIssueData;
	topRowActions?: ReactNode;
	isLoading?: boolean;
	isFullPageMode?: boolean;
	showNote?: boolean;
}

const LABELS_SECTION_ID = 'labels-section-title';

const DetailsBlock: FC<IDetailsProps> = ({
	issue,
	isLoading,
	topRowActions,
	isFullPageMode,
	showNote
}: IDetailsProps) => {
	const subheadingsLevel = useMemo(() => (isFullPageMode ? 2 : 3), [isFullPageMode]);

	const {
		type,
		severity,
		elements,
		level,
		labels,
		summary,
		cause,
		howToFix,
		effect,
		knowledgeBaseLink,
		tags,
		pageUrl,
		pageTitle,
		note,
		screenshotDetails
	} = issue;

	if (isLoading) {
		return (
			<div className="spinner-wrapper">
				<EvSpinner />
			</div>
		);
	}
	if (!type) {
		return null;
	}
	return (
		<div className="details-content">
			<div className="section-header">
				<div className={classNames('title-block', { 'with-hidden-title': !isFullPageMode })}>
					{isFullPageMode && (
						<EvTitle titleText={type || ''} headingLevel={1} mode={TITLE_MODES.MAIN} />
					)}
					{topRowActions}
				</div>
				<div className="sub-title">{summary}</div>
			</div>

			<div className="element-details">
				<div className="details-block">
					<TextBlockWithTitle
						name="URL:"
						value={<EvLink url={pageUrl} showIcon={true} openInNewTab={true} />}
						headingLevel={subheadingsLevel}
					/>
					<TextBlockWithTitle
						name="Page Title:"
						value={pageTitle}
						headingLevel={subheadingsLevel}
					/>
				</div>
				<div className="details-block">
					{elements.map((element) => (
						<ElementDetailsBlock
							elementId={element.id}
							componentId={element.componentId}
							selector={element.selector}
							subheadingsLevel={subheadingsLevel}
							domSnippet={element.domSnippet}
							key={element.id}
						/>
					))}
				</div>
				{labels?.length ? (
					<>
						<EvTitle
							titleText={`Labels (${labels.length})`}
							id={LABELS_SECTION_ID}
							mode={TITLE_MODES.NORMAL}
							className="subheading"
							headingLevel={subheadingsLevel}
						/>
						<EvSection ariaLabelledby={LABELS_SECTION_ID}>
							<CollapsiblePane
								className="labels-collapsible-pane"
								containerClassName="labels-section"
								collapseTitle="Show less labels"
								expandTitle="Show more labels"
							>
								<LabelsBlock labels={labels} isThreeColumns />
							</CollapsiblePane>
						</EvSection>
					</>
				) : null}
				<div className="details-block dual">
					<div className="half-block">
						<div className="severity-level">
							<TextBlockWithTitle
								name="Severity:"
								value={severity}
								headingLevel={subheadingsLevel}
							/>
							<TextBlockWithTitle name="Level:" value={level} headingLevel={subheadingsLevel} />
							<IssueMarkdownTextBlock
								sectionName="Cause:"
								stringToRender={cause}
								headingLevel={subheadingsLevel}
							/>
							<IssueMarkdownTextBlock
								sectionName="Effect:"
								stringToRender={effect}
								headingLevel={subheadingsLevel}
							/>
							<KnowledgeBaseLink link={knowledgeBaseLink} />
						</div>
					</div>
					<div className="half-block">
						<HowToFixBlock howToFix={howToFix} subheadingsLevel={subheadingsLevel} />
						<WcagTagsLinksBlock tags={tags} subheadingsLevel={subheadingsLevel} />
					</div>
				</div>
			</div>
			{showNote ? <NoteBlock note={note} subheadingsLevel={subheadingsLevel} /> : null}
			<ScreenshotBlock screenshotDetails={screenshotDetails} subheadingsLevel={subheadingsLevel} />
		</div>
	);
};

export default DetailsBlock;

import React, { FC } from 'react';
import { EvSnippetView, HeadingLevelRange } from '@evinced-private/ui-common';
import NotGroupedComponent from '../../../consts/NotGroupedComponent';
import { TextBlockWithTitle } from '../text-block-with-title/TextBlockWithTitle';
import { SelectorTextBlock } from '../text-block-with-title/selector-text-block/SelectorTextBlock';

interface IElementDetailsBlockProps {
	elementId: string;
	componentId: string;
	selector: string;
	subheadingsLevel: HeadingLevelRange;
	domSnippet: string;
}

export const ElementDetailsBlock: FC<IElementDetailsBlockProps> = ({
	elementId,
	componentId,
	selector,
	subheadingsLevel,
	domSnippet
}): JSX.Element => {
	return (
		<div className="component-details" key={elementId}>
			<TextBlockWithTitle
				name="Component:"
				value={
					componentId === NotGroupedComponent.ID ? NotGroupedComponent.DISPLAY_NAME : componentId
				}
				headingLevel={subheadingsLevel}
			/>
			<SelectorTextBlock selector={selector} headingLevel={subheadingsLevel} />
			{domSnippet ? (
				<div className="snippet" aria-label="DOM Snippet">
					<EvSnippetView title="" snippet={domSnippet} codeType="html" />
				</div>
			) : null}
		</div>
	);
};

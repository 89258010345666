import { IWebSdkTestBaseData } from '../../../WebSdkDashboardService.types';
import { TestBaseDataQuery } from '../TestBaseData.generated';

export const transformTestBaseDataResults = (
	rawResults: TestBaseDataQuery
): IWebSdkTestBaseData | null => {
	if (!rawResults.wsdk_test_runs?.length) {
		return null;
	}

	const test = rawResults.wsdk_test_runs[0];
	if (!test) {
		return null;
	}

	return {
		runTime: test.run_time,
		name: test.test_name
	};
};

import { EvErrorCard, useSkipLinks } from '@evinced-private/ui-common';
import React, { FC, useState } from 'react';
import { useParams } from 'react-router';
import { WfaDetailsBlock } from 'src/components/wfa/WfaDetailsBlock';
import { DASHBOARD_ERROR_MSG } from 'src/pages/dashboards-container/errorMessages';
import { useWhiteBackground } from 'src/hooks/useWhiteBackground';
import { useWfaSessionNameQuery } from '../hooks/useWfaSessionNameQuery';
import { useWfaSessionBreadcrumbs } from '../hooks/useWfaSessionBreadcrumbs';
import './WfaSingleIssuePage.scss';

export const WfaSingleIssuePage: FC = () => {
	const { sessionId, issueId } = useParams<{ sessionId: string; issueId: string }>();
	const [error, setError] = useState(null);

	useSkipLinks(true);
	useWhiteBackground();
	const sessionNameQuery = useWfaSessionNameQuery(sessionId);
	useWfaSessionBreadcrumbs(sessionId, sessionNameQuery?.data ?? '');

	return (
		<div className="issue-details-page">
			{error ? (
				<EvErrorCard title="Error" contactUsMessage={DASHBOARD_ERROR_MSG} />
			) : (
				<WfaDetailsBlock runId={sessionId} issueId={issueId} isFullPageMode setError={setError} />
			)}
		</div>
	);

	return <div />;
};

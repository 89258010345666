import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { IWfaSessionsFilterOption } from 'src/helpers/DataFilterHelper';
import { SummaryItem } from 'src/interfaces/SummaryItem';
import { TESTS_REFETCH_INTERVAL } from 'src/pages/web-sdk-dashboard/web-sdk-consts';
import { WFA_USAGE_DATA } from 'src/providers/reactQueryProvider/QueryKeys';
import { getWfaUsageData } from 'src/services/wfa-dashboard/WfaDashboardService';

export const useWfaUsageDataQuery = (
	filters: IWfaSessionsFilterOption
): UseQueryResult<SummaryItem[]> => {
	const query = useQuery(
		[WFA_USAGE_DATA, filters],
		async (): Promise<SummaryItem[]> => getWfaUsageData(filters),
		{
			refetchInterval: TESTS_REFETCH_INTERVAL
		}
	);

	return query;
};

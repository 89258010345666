import React from 'react';
import { EvTableColumn, MultilineTextCellFormatter } from '@evinced-private/ui-common';
import { ShowIssueDetailsCellFormatter } from 'src/components/common/show-details-cell-formatter/ShowIssueDetailsCellFormatter';
import { IWfaSessionIssue } from 'src/services/wfa-dashboard/WfaDashboardService.types';
import { formatDate } from 'src/helpers/DateFormatHelper';
import { NAME_NOT_PROVIDED } from 'src/pages/wfa-dashboard/WfaDashboardConsts';
import { ProductType } from '../../../../../interfaces/Tenant';

export const getWfaAllIssuesColumns = (): EvTableColumn[] => {
	return [
		{
			dataField: 'type',
			text: 'Issue Type',
			style: { width: '138px' },
			headerStyle: { width: '138px' },
			sort: true
		},
		{
			dataField: 'severity',
			text: 'Severity',
			style: { width: '99px' },
			headerStyle: { width: '99px' },
			sort: true
		},
		{
			dataField: 'components',
			text: 'Component',
			style: { width: '121px' },
			headerStyle: { width: '121px' },
			formatter: (components: string[]) => {
				return <MultilineTextCellFormatter items={components} itemType="component" />;
			}
		},
		{
			dataField: 'selectors',
			text: 'Selector',
			style: { width: '220px', maxWidth: '220px' },
			headerStyle: { width: '220px', maxWidth: '220px' },
			formatter: (selectors: string[]) => {
				return <MultilineTextCellFormatter items={selectors} itemType="selector" showTooltip />;
			}
		},
		{
			dataField: 'url',
			text: 'Url',
			sort: true,
			style: { width: '240px', maxWidth: '240px' },
			headerStyle: { width: '240px', maxWidth: '240px' },
			classes: 'wfa-all-issues-table-url-column'
		},
		{
			dataField: 'pageTitle',
			text: 'Page Title',
			sort: true,
			style: { maxWidth: '180px' },
			headerStyle: { maxWidth: '180px' }
		},
		{
			dataField: '',
			text: '',
			style: { width: '80px', paddingRight: '24px', paddingLeft: '24px' },
			headerStyle: { width: '80px' },
			formatter: (cell, { id, runTime, sessionName, sessionId, type }: IWfaSessionIssue) => {
				return (
					<ShowIssueDetailsCellFormatter
						product={ProductType.WEB_FLOW_ANALYZER}
						runId={sessionId}
						issueId={id}
						issueType={type}
						infoTitle={`${sessionName || NAME_NOT_PROVIDED} session at ${formatDate(runTime)}`}
					/>
				);
			}
		}
	];
};

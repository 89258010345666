import React, { FC } from 'react';
import { HeadingLevelRange } from '@evinced-private/ui-common';
import { COPY_SELECTOR_BUTTON_DAID } from '../../../../consts/data-automation-ids';
import CopyToClipboard from '../../copy-to-clipboard/CopyToClipboard';
import { TextBlockWithTitle } from '../TextBlockWithTitle';
import './SelectorTextBlock.scss';

interface ISelectorTextBlockProps {
	selector?: string;
	headingLevel: HeadingLevelRange;
}

export const SelectorTextBlock: FC<ISelectorTextBlockProps> = ({ selector, headingLevel }) => {
	const value = (
		<div className="selector-with-copy">
			{selector}
			<CopyToClipboard
				renderJustIcon
				textToCopy={selector}
				dataAutomationId={COPY_SELECTOR_BUTTON_DAID}
			/>
		</div>
	);

	return <TextBlockWithTitle name="Selector:" value={value} headingLevel={headingLevel} />;
};

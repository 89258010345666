import { useMemo } from 'react';
import { usePermissions } from 'src/hooks/usePermissions';
import { ProductType } from 'src/interfaces/Tenant';
import { useBreadcrumbRoutes } from 'src/providers/breadcrumbsProvider/BreadcrumbsProvider';
import { getWebSdkDashboardBreadcrumbsRoutes } from '../web-sdk-helpers';

export const useWebSdkTestBreadcrumbs = (
	runId: string,
	testName: string,
	runTime: string
): void => {
	const breadcrumbRoutes = useMemo(
		() => getWebSdkDashboardBreadcrumbsRoutes(runId, testName, runTime),
		[runId, testName, runTime]
	);

	const isPlatformEnabledForWebSdks = usePermissions(ProductType.WEB_SDK);
	// do not display breadcrumbs if the user has no access to the dashboard
	useBreadcrumbRoutes(isPlatformEnabledForWebSdks ? breadcrumbRoutes : []);
};

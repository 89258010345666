import { OptionType } from '@evinced-private/ui-common';
import { WfaUserOptionsQuery } from '../WfaUserOptions.generated';

export const transformUserOptionsResults = (rawData: WfaUserOptionsQuery): OptionType[] => {
	const rawResult = rawData.wfa_session_runs?.[0]?.user_name_group_uniq_array;

	return (
		rawResult?.map((userName) => ({
			label: userName,
			value: userName
		})) ?? []
	);
};

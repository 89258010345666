import { Location } from 'history';
import { IFilterOptions } from 'src/helpers/DataFilterHelper';
import { SummaryItem } from '../../../interfaces/SummaryItem';
import MathHelper from '../../../helpers/MathHelper';
import { IGetMfaSessionsResponse, IMFALastMonthSummary, MfaSession } from '../mfa-types';
import RoutesHelper from '../../../helpers/RoutesHelper';
import { IEvBreadcrumb } from '../../../components/header-navigation/EvBreadcrumbs';
import { MAIN_PAGE_NAV_LABEL } from '../mfa-consts';

const MILIS_PER_DAY = 24 * 60 * 60 * 1000;
const DAYS_TO_LOOK_BACK = 30;

export const getSessionIdParam = (location): string => {
	const params = location.search;
	return new URLSearchParams(params).get('sessionId');
};

export const getSessionNameParam = (location): string => {
	const params = location.search;
	return new URLSearchParams(params).get('sessionName');
};

export const buildTableFilters = (sessions: MfaSession[]): IFilterOptions => {
	const appsList: string[] = [];
	const osList: string[] = [];
	const versionsList: string[] = [];

	// Loop over all sessions and add their apps, os and versions to the relevant lists
	sessions.forEach((session: MfaSession) => {
		appsList.push(...session.apps);
		osList.push(...session.os);
		versionsList.push(...session.versions);
	});

	// Create a set from sorted-lists to remove duplicates
	const apps = new Set<string>(appsList.sort());
	const os = new Set<string>(osList.sort());
	const versions = new Set<string>(versionsList.sort());

	return {
		searchValue: '',
		apps: Array.from(apps, (app) => ({ value: app, label: app })),
		os: Array.from(os, (os) => ({ value: os, label: os })),
		versions: Array.from(versions, (version) => ({ value: version, label: version }))
	};
};

export const calcMFASummaryDataOverTwoMonths = (sessions: MfaSession[]): IMFALastMonthSummary => {
	let totalSessionsThisMonth = 0;
	let totalScansThisMonth = 0;
	let totalCriticalIssuesThisMonth = 0;

	let totalSessionsPrevMonth = 0;
	let totalScansPrevMonth = 0;
	let totalCriticalIssuesPrevMonth = 0;

	const today = new Date();
	const daysAgoInMilis = DAYS_TO_LOOK_BACK * MILIS_PER_DAY;
	const thirtyDaysAgo = new Date(today.getTime() - daysAgoInMilis);
	const sixtyDaysAgo = new Date(thirtyDaysAgo.getTime() - daysAgoInMilis);

	sessions.forEach((session: MfaSession) => {
		const sessionLastModifiedDate = new Date(session.lastModified);

		// If session is from the past month
		if (sessionLastModifiedDate >= thirtyDaysAgo && sessionLastModifiedDate <= today) {
			totalSessionsThisMonth++;
			totalScansThisMonth += session.totalScans;
			totalCriticalIssuesThisMonth += session.criticalIssues;
		}

		// If session is from 2 months ago
		if (sessionLastModifiedDate >= sixtyDaysAgo && sessionLastModifiedDate < thirtyDaysAgo) {
			totalSessionsPrevMonth++;
			totalScansPrevMonth += session.totalScans;
			totalCriticalIssuesPrevMonth += session.criticalIssues;
		}
	});

	const totalSessionsLift: number = MathHelper.calcPercentageDifference(
		totalSessionsThisMonth,
		totalSessionsPrevMonth
	);

	const totalScansLift: number = MathHelper.calcPercentageDifference(
		totalScansThisMonth,
		totalScansPrevMonth
	);

	const totalCriticalIssuesLift: number = MathHelper.calcPercentageDifference(
		totalCriticalIssuesThisMonth,
		totalCriticalIssuesPrevMonth
	);

	return {
		totalSessions: totalSessionsThisMonth,
		totalScans: totalScansThisMonth,
		totalCriticalIssues: totalCriticalIssuesThisMonth,
		totalSessionsLift,
		totalScansLift,
		totalCriticalIssuesLift
	};
};

export const buildMFASummaryPaneData = (mfaSessions: MfaSession[]): SummaryItem[] => {
	const mfaSummaryData: IMFALastMonthSummary = calcMFASummaryDataOverTwoMonths(mfaSessions);
	return [
		{
			value: mfaSummaryData.totalSessions,
			label: 'Total sessions',
			lift: mfaSummaryData.totalSessionsLift
		},
		{
			value: mfaSummaryData.totalScans,
			label: 'Total scans',
			lift: mfaSummaryData.totalScansLift
		},
		{
			value: mfaSummaryData.totalCriticalIssues,
			label: 'Critical issues',
			lift: mfaSummaryData.totalCriticalIssuesLift
		}
	];
};

export const buildMFASessionsList = (
	mfaSessionsFromEndpoint: IGetMfaSessionsResponse[]
): MfaSession[] => {
	return mfaSessionsFromEndpoint.map((session) => {
		return {
			...session.data,
			searchStr: `${session.data.sessionName} ${session.data.owner}`
		};
	});
};

export const getMfaDashboardBreadcrumbsRoutes = (location: Location<object>): IEvBreadcrumb[] => {
	return [
		{
			id: MAIN_PAGE_NAV_LABEL,
			url: RoutesHelper.getMfaDashboardPath(),
			title: MAIN_PAGE_NAV_LABEL
		},
		{
			id: getSessionIdParam(location),
			url: '',
			title: getSessionNameParam(location)
		}
	];
};

export const assertSessionExtraData = (res: IGetMfaSessionsResponse[]): IGetMfaSessionsResponse[] =>
	res.map((sessionRow) => {
		sessionRow.data.sessionLink = RoutesHelper.getMfaSessionReportPath(sessionRow.data);
		sessionRow.data.totalIssuesWithoutBestPractices =
			sessionRow.data.totalIssues - (sessionRow.data.bestPracticeIssues || 0);
		return { ...sessionRow };
	});

import { EVINCED_SEVERITIES_IDS } from '@evinced-private/ui-common/services';
import { SeverityCountType } from '../../../../../types/wfa/SeverityCountType';
import { WfaSessionSeveritiesQuery } from '../SessionSeverities.generated';

export const transformSessionSeveritiesResult = (
	rawResults: WfaSessionSeveritiesQuery
): SeverityCountType[] => {
	const severitiesCount = rawResults?.wfa_session_runs?.[0];
	return [
		{
			id: EVINCED_SEVERITIES_IDS.CRITICAL,
			name: 'Critical',
			count: severitiesCount?.critical_issues || 0,
			severity: 'Critical'
		},
		{
			id: EVINCED_SEVERITIES_IDS.SERIOUS,
			name: 'Serious',
			count: severitiesCount?.serious_issues || 0,
			severity: 'Serious'
		},
		{
			id: EVINCED_SEVERITIES_IDS.MODERATE,
			name: 'Moderate',
			count: severitiesCount?.moderate_issues || 0,
			severity: 'Moderate'
		},
		{
			id: EVINCED_SEVERITIES_IDS.MINOR,
			name: 'Minor',
			count: severitiesCount?.minor_issues || 0,
			severity: 'Minor'
		},
		{
			id: EVINCED_SEVERITIES_IDS.NEEDS_REVIEW,
			name: 'Needs Review',
			count: severitiesCount?.needs_review_issues || 0,
			severity: 'Needs Review'
		},
		{
			id: EVINCED_SEVERITIES_IDS.BEST_PRACTICE,
			name: 'Best Practice',
			count: severitiesCount?.best_practice_issues || 0,
			severity: 'Best Practice'
		}
	];
};

import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { WEB_SDK_TEST_BASE_DATA } from 'src/providers/reactQueryProvider/QueryKeys';
import { getWebSdkTestBaseData } from 'src/services/web-sdk-dashboard/WebSdkDashboardService';
import { IWebSdkTestBaseData } from 'src/services/web-sdk-dashboard/WebSdkDashboardService.types';

export const useWebSdkTestBaseDataQuery = (runId: string): UseQueryResult<IWebSdkTestBaseData> => {
	const testBaseDataQuery = useQuery([WEB_SDK_TEST_BASE_DATA, runId], () =>
		getWebSdkTestBaseData(runId)
	);

	return testBaseDataQuery;
};

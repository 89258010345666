import React, { FC } from 'react';
import { EvLift, FormatHelper } from '@evinced-private/ui-common';
import { Link } from 'react-router-dom';
import {
	SUMMARY_PANE_ITEM_DAID,
	SUMMARY_PANE_ITEM_LABEL_DAID,
	SUMMARY_PANE_ITEM_LIFT_DAID,
	SUMMARY_PANE_ITEM_VALUE_DAID
} from '../../../consts/data-automation-ids';
import { SummaryItem } from '../../../interfaces/SummaryItem';
import EvSection from '../ev-section/EvSection';
import './SummaryPane.scss';

interface ISummaryPaneProps {
	items: SummaryItem[];
	sectionLabel?: string;
	labeledById?: string;
	className?: string;
	shouldHaveSkipLink?: boolean;
	showLift?: boolean;
	renderActionButtons?: () => JSX.Element;
	ariaLabel?: string;
}

const SummaryPane: FC<ISummaryPaneProps> = ({
	items,
	className,
	sectionLabel = '',
	labeledById = '',
	shouldHaveSkipLink = true,
	showLift = true,
	renderActionButtons,
	ariaLabel
}: ISummaryPaneProps) => {
	const renderPaneContent = (value, label, lift: number): JSX.Element => (
		<div className="summary-pane-content">
			<div className="label" aria-hidden="true" data-automation-id={SUMMARY_PANE_ITEM_LABEL_DAID}>
				{label}
			</div>
			<div className="value" aria-hidden="true" data-automation-id={SUMMARY_PANE_ITEM_VALUE_DAID}>
				{FormatHelper.formatNumber(value)}
			</div>
			{showLift ? (
				<EvLift
					dataAutomationId={SUMMARY_PANE_ITEM_LIFT_DAID}
					liftNumber={lift}
					small={true}
					success={lift > 0}
				/>
			) : null}
		</div>
	);

	const renderSummaryItem = ({ label, value, link, lift }: SummaryItem): JSX.Element => {
		// TODO: ask Gilad what should be the aria label text.
		let ariaLabelSuffix = '';
		if (showLift) {
			ariaLabelSuffix =
				lift != null
					? ` Lift ${lift > 0 ? 'increased' : 'decreased'} by ${lift?.toFixed?.(2)} precent.`
					: ' Lift data is not available.';
		}

		return (
			<li
				className="summary-pane-item"
				key={label}
				aria-label={`${value} ${label}.${ariaLabelSuffix}`}
				data-automation-id={SUMMARY_PANE_ITEM_DAID}
			>
				{link ? (
					<Link to={link} aria-label={`${label} details`}>
						{renderPaneContent(value, label, lift)}
					</Link>
				) : (
					renderPaneContent(value, label, lift)
				)}
			</li>
		);
	};
	return (
		<EvSection
			ariaLabel={sectionLabel}
			ariaLabelledby={labeledById}
			skipLinkId={shouldHaveSkipLink ? 'summary' : null}
		>
			<ul className={`summary-pane ${className}`} aria-label={ariaLabel}>
				{items.map(renderSummaryItem)}
				{renderActionButtons?.()}
			</ul>
		</EvSection>
	);
};

export default SummaryPane;

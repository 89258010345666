import React, { FC } from 'react';
import { EvTitle, HeadingLevelRange, TITLE_MODES } from '@evinced-private/ui-common';

interface INoteBlockProps {
	subheadingsLevel: HeadingLevelRange;
	note?: string;
}

export const NoteBlock: FC<INoteBlockProps> = ({ note, subheadingsLevel }) => {
	return (
		<div className="ev-issue-note-block">
			<EvTitle
				id="issue-notes"
				titleText="Notes"
				mode={TITLE_MODES.NORMAL}
				className="subheading"
				headingLevel={subheadingsLevel}
			/>
			<textarea
				title="Notes"
				aria-labelledby="issue-notes"
				value={note}
				// TODO: add non-readOnly mode when moving to ui-common
				readOnly
			/>
		</div>
	);
};

/* eslint-disable camelcase */
import { IWfaSessionsFilterOption } from 'src/helpers/DataFilterHelper';
import {
	PagesTextSearchFields,
	Wfa_Session_RunsTextSearchFields
} from 'src/types/graphql.generated';
import { WfaUsageDataQueryVariables } from '../WfaUsageData.generated';
import { QueryVariablesBuilder } from '../../../../../helpers/GQLRequestsHelper/QueryVariablesBuilder';

const DAYS_AMOUNT_FILTER_PARAM_KEY = 'daysAmountFilter';
const DAYS_AMOUNT_X2_FILTER_PARAM_KEY = 'daysAmountx2Filter';
const CREATED_BY_FILTER_PARAM_KEY = 'createdByFilter';

const sessionsSearchFields = [Wfa_Session_RunsTextSearchFields.SessionName];

export const buildWfaUsageDataVariables = (
	filters: IWfaSessionsFilterOption
): WfaUsageDataQueryVariables => {
	const variablesBuilder = new QueryVariablesBuilder<WfaUsageDataQueryVariables>({
		searchFields: sessionsSearchFields
	});

	const { searchValue, sessionsPeriod, createdBy } = filters;
	const sessionsSinglePeriod = sessionsPeriod?.[0];

	variablesBuilder
		.addTextSearchParam(searchValue ?? '')
		.addFilterParam(CREATED_BY_FILTER_PARAM_KEY, createdBy);

	if (
		sessionsSinglePeriod?.value &&
		sessionsSinglePeriod.value !== 'all' &&
		typeof sessionsSinglePeriod.value === 'number'
	) {
		variablesBuilder
			.addConditionParam(DAYS_AMOUNT_FILTER_PARAM_KEY, sessionsSinglePeriod.value)
			.addConditionParam(DAYS_AMOUNT_X2_FILTER_PARAM_KEY, 2 * sessionsSinglePeriod.value);
	}

	const variables = variablesBuilder.getVariables();

	return variables.textSearch
		? {
				...variables,
				textSearchPages: {
					...variables.textSearch,
					searchFields: [PagesTextSearchFields.SessionName]
				}
			}
		: variables;
};

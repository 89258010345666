import React, { FC, useState } from 'react';
import { useParams } from 'react-router';
import { useSkipLinks, EvErrorCard } from '@evinced-private/ui-common';
import { useWhiteBackground } from 'src/hooks/useWhiteBackground';
import { DASHBOARD_ERROR_MSG } from '../dashboards-container/errorMessages';
import WebSdkDetailsBlock from '../../components/web-sdk/WebSdkDetailsBlock';
import { useWebSdkTestBreadcrumbs } from './hooks/useWebSdkTestBreadcrumbs';
import { useWebSdkTestBaseDataQuery } from './hooks/useWebSdkTestBaseDataQuery';
import './WebSdkIssuePage.scss';

const WebSdkIssuePage: FC = () => {
	useSkipLinks(true);
	const [error, setError] = useState(null);
	const { runId, issueId } = useParams<{ [key: string]: string }>();

	const testBaseDataQuery = useWebSdkTestBaseDataQuery(runId);

	useWebSdkTestBreadcrumbs(runId, testBaseDataQuery.data?.name, testBaseDataQuery?.data?.runTime);
	useWhiteBackground();

	return (
		<div className="issue-details-page">
			{error ? (
				<EvErrorCard title="Error" contactUsMessage={DASHBOARD_ERROR_MSG} />
			) : (
				<WebSdkDetailsBlock runId={runId} issueId={issueId} isFullPageMode setError={setError} />
			)}
		</div>
	);
};

export default WebSdkIssuePage;

import { OptionType } from '@evinced-private/ui-common';
import { buildRunIdParam } from 'src/helpers/GQLRequestsHelper/buildRunIdParam';
import { IWebSdkTestIssuesFilterOption } from 'src/helpers/DataFilterHelper';
import { gqlClient } from '../../api/GraphQLRequestApi';

import { UsageData, RecentUsageData } from './queries/UsageData/UsageData.gql';
import { Tests, RecentTests } from './queries/Tests/Tests.gql';
import { TestDetails } from './queries/TestDetails/TestDetails.gql';
import { IssuesFilterOptions } from './queries/IssuesFilterOptions/IssuesFilterOptions.gql';
import { Issues } from './queries/Issues/Issues.gql';
import { TestLabels } from './queries/TestLabels/TestLabels.gql';
import { TestBaseData } from './queries/TestBaseData/TestBaseData.gql';

import { buildUsageDataVariables } from './queries/UsageData/helpers/buildVariables';
import { buildTestsQueryVariables } from './queries/Tests/helpers/buildVariables';
import { buildIssuesVariables } from './queries/Issues/helpers/buildVariables';

import { transformUsageDataResults } from './queries/UsageData/helpers/transformResults';
import { transformTestsResults } from './queries/Tests/helpers/transformResults';
import { transformTestDetailsResults } from './queries/TestDetails/helpers/transformResults';
import { transformIssuesFilterOptionsResults } from './queries/IssuesFilterOptions/helpers/transformResults';
import { transformIssuesResults } from './queries/Issues/helpers/transformResults';
import { transformTestLabelsResults } from './queries/TestLabels/helpers/transformResults';
import { transformTestBaseDataResults } from './queries/TestBaseData/helpers/transformResults';

import { UsageDataQuery } from './queries/UsageData/UsageData.generated';
import { TestsQuery } from './queries/Tests/Tests.generated';
import { TestDetailsQuery } from './queries/TestDetails/TestDetails.generated';
import { IssuesFilterOptionsQuery } from './queries/IssuesFilterOptions/IssuesFilterOptions.generated';
import { IssuesQuery } from './queries/Issues/Issues.generated';
import { TestLabelsQuery } from './queries/TestLabels/TestLabels.generated';
import { TestBaseDataQuery } from './queries/TestBaseData/TestBaseData.generated';

import {
	IGetWebSdkTestIssuesParams,
	IGetWebSdkTestsParams,
	IWebSdkTestDetails,
	TWebSdkTestIssuesResult,
	TWebSdkTestsResult,
	IWebSdkTestBaseData
} from './WebSdkDashboardService.types';
import { SummaryItem } from '../../interfaces/SummaryItem';
import Logger from '../Logger';

export const getWebSdkUsageData = async (
	isRecentTests: boolean,
	daysFilter?: OptionType,
	searchValue?: string
): Promise<SummaryItem[]> => {
	try {
		const testsVariables = buildUsageDataVariables(daysFilter, searchValue);

		const query = isRecentTests ? RecentUsageData : UsageData;
		const result = await gqlClient.request<UsageDataQuery>(query, testsVariables);

		return transformUsageDataResults(result, daysFilter);
	} catch (e) {
		Logger.error('WebSdkDashboardService -> getWebSdkUsageData failed with an error:', e);
		throw e;
	}
};

export const getWebSdkTests = async ({
	isRecentTests,
	...restParams
}: IGetWebSdkTestsParams): Promise<TWebSdkTestsResult> => {
	try {
		const variables = buildTestsQueryVariables(restParams);

		const query = isRecentTests ? RecentTests : Tests;
		const result = await gqlClient.request<TestsQuery>(query, variables);

		return transformTestsResults(result);
	} catch (e) {
		Logger.error('WebSdkDashboardService -> getWebSdkTests failed with an error:', e);
		throw e;
	}
};

export const getWebSdkTestDetails = async (runId: string): Promise<IWebSdkTestDetails> => {
	try {
		const variables = buildRunIdParam(runId);

		const result = await gqlClient.request<TestDetailsQuery>(TestDetails, variables);

		const transformedResult = transformTestDetailsResults(result);
		if (!transformedResult) {
			throw new Error(`Test ${runId} not found.`);
		}
		return transformedResult;
	} catch (e) {
		Logger.error('WebSdkDashboardService -> getWebSdkTestDetails failed with an error:', e);
		throw e;
	}
};

export const getWebSdkTestIssuesFilterOptions = async (
	runId: string
): Promise<Omit<IWebSdkTestIssuesFilterOption, 'searchValue'>> => {
	try {
		const variables = buildRunIdParam(runId);

		const result = await gqlClient.request<IssuesFilterOptionsQuery>(
			IssuesFilterOptions,
			variables
		);

		return transformIssuesFilterOptionsResults(result);
	} catch (e) {
		Logger.error(
			'WebSdkDashboardService -> getWebSdkTestIssuesFilterOptions failed with an error:',
			e
		);
		throw e;
	}
};

export const getWebSdkTestIssues = async (
	params: IGetWebSdkTestIssuesParams
): Promise<TWebSdkTestIssuesResult> => {
	try {
		const variables = buildIssuesVariables(params);

		const result = await gqlClient.request<IssuesQuery>(Issues, variables);

		const transformedResult = transformIssuesResults(result);
		if (!transformedResult) {
			throw new Error(`Test ${params.runId} not found.`);
		}
		return transformedResult;
	} catch (e) {
		Logger.error('WebSdkDashboardService -> getWebSdkTestIssues failed with an error:', e);
		throw e;
	}
};

export const getWebSdkTestLabels = async (): Promise<OptionType[]> => {
	try {
		const result = await gqlClient.request<TestLabelsQuery>(TestLabels);

		return transformTestLabelsResults(result);
	} catch (e) {
		Logger.error('WebSdkDashboardService -> getWebSdkTestLabels failed with an error:', e);
		throw e;
	}
};

export const getWebSdkTestBaseData = async (runId: string): Promise<IWebSdkTestBaseData> => {
	try {
		const variables = buildRunIdParam(runId);

		const result = await gqlClient.request<TestBaseDataQuery>(TestBaseData, variables);

		const transformedResult = transformTestBaseDataResults(result);
		if (!transformedResult) {
			throw new Error(`Test ${runId} not found.`);
		}
		return transformedResult;
	} catch (e) {
		Logger.error('WebSdkDashboardService -> getWebSdkTestBaseData failed with an error:', e);
		throw e;
	}
};
